<template>
  <div class="auth">
    <div class="wrapper forgot-password">
      <div class="content">
        <div class="logo">
          <h1>
            <app-i18n code="app.title"></app-i18n>
          </h1>
        </div>

        <el-form
          :model="model"
          :rules="rules"
          @submit.prevent.native="doSubmit"
          class="form"
          label-position="left"
          ref="form"
        >
          <div class="logo">
            <h5>
              <app-i18n code="passwordChange.title"></app-i18n>
            </h5>
          </div>

          <el-form-item
            :label="fields.newPassword.label"
            :prop="fields.newPassword.name"
            :required="fields.newPassword.required"
          >
            <el-input
              ref="focus"
              :placeholder="
                getTranslation('passwordChange.newPassword')
              "
              auto-complete="off"
              type="password"
              v-model="model[fields.newPassword.name]"
            ></el-input>
          </el-form-item>

          <el-form-item
            :label="fields.newPasswordConfirmation.label"
            :prop="fields.newPasswordConfirmation.name"
            :required="fields.newPasswordConfirmation.required"
          >
            <el-input
              :placeholder="
                getTranslation('passwordChange.confirmNewPassword')
              "
              auto-complete="off"
              type="password"
              v-model="model[fields.newPasswordConfirmation.name]"
            ></el-input>
          </el-form-item>

          <el-form-item class="form-buttons">
            <el-button
              :loading="loading"
              class="w-100"
              native-type="submit"
              type="primary"
            >
              <app-i18n code="common.submit"></app-i18n>
            </el-button>
          </el-form-item>

        <!--   <div class="other-actions">
            <router-link :to="{ path: '/auth/signin' }">
              <el-button type="text">
                <app-i18n code="common.cancel"></app-i18n>
              </el-button>
            </router-link>
          </div> -->
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { UserModel } from '@/modules/auth/user-model';
import { FormSchema } from '@/shared/form/form-schema';
import { i18n } from '../../../i18n';

const { fields } = UserModel;
const formSchema = new FormSchema([
  fields.newPassword,
  fields.newPasswordConfirmation,
]);

export default {
  name: 'app-password-initial-change-page',

  data() {
    const rules = formSchema.rules();

    const passwordConfirmationValidator = (rule, value, callback) => {
      if (value !== this.model[fields.newPassword.name]) {
        callback(new Error(i18n('auth.passwordChange.mustMatch')));
      } else {
        callback();
      }
    };

    return {
      rules: {
        ...rules,
        [fields.newPasswordConfirmation.name]: [
          ...rules[fields.newPasswordConfirmation.name],
          {
            validator: passwordConfirmationValidator,
            trigger: 'blur',
          },
        ],
      },
      model: null,
    };
  },

  computed: {
    ...mapGetters({
      loading: 'auth/loadingInitialPasswordChange',
    }),

    fields() {
      return fields;
    },
  },

  async created() {
    this.model = formSchema.initialValues(this.currentUser);
  },

  methods: {
    ...mapActions({
      doInitialPasswordChange: 'auth/doInitialPasswordChange',
    }),

    getTranslation(value) {
      return i18n(value);
    },

    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      await this.doInitialPasswordChange({
        password: this.model.newPassword,
      });
    },
  },
};
</script>
